<template>
  <van-form>
    <div class="popup__header">新增 IR Member</div>
    <div v-if="v$.$silentErrors.length" class="bd-callout bd-callout-warning">
      <strong>填入資料錯誤</strong>
      請修正以下 {{ v$.$silentErrors.length }} 個欄位： {{ errorFields }}
    </div>
    <div class="popup__content">
      <van-field
        v-model="addEngineerData.DisplayName"
        type="text"
        name="EngineerEnglishName"
        class="fieldRow"
        :class="{
          invalid: v$.DisplayName.displayNameRule.$invalid,
          valid: !v$.DisplayName.displayNameRule.$invalid,
        }"
        label="英文姓名"
        label-class="fieldRow__label fieldRow__label--required"
        placeholder="Lyn Chuang"
        :border="false"
      />
      <div
        v-if="v$.DisplayName.displayNameRule.$invalid"
        class="van-field__error-message"
      >
        {{ validationRegex.englishName.errMessage }}
      </div>
      <small class="fieldRow__note"> 格式參考 ex: Lyn Chuang </small>
      <van-field
        v-model="addEngineerData.Email"
        type="email"
        name="mail"
        class="fieldRow"
        :class="{
          invalid: v$.Email.emailRule1.$invalid,
          valid: !v$.Email.emailRule1.$invalid,
        }"
        label="Email"
        label-class="fieldRow__label fieldRow__label--required"
        :border="false"
      />
      <div v-if="v$.Email.emailRule1.$invalid" class="van-field__error-message">
        {{ validationRegex.email.errMessage }}
      </div>
      <div v-if="v$.Email.emailRule2.$invalid">此email已被使用！</div>
    </div>
    <div class="popup__footer">
      <button
        class="btn btn-primary"
        :disabled="v$.$invalid"
        @click="handleAddEngineerData"
      >
        新增 IR Member
      </button>
    </div>
  </van-form>
</template>

<script setup>
import { ref, reactive, computed, defineEmits, watch } from "vue";
import { useStore } from "vuex";
import { showDialog } from "vant";
import formHelper from "../formHelper";
import { useVuelidate } from "@vuelidate/core";

const validationRegex = formHelper.validation;
const errorFields = ref(null);
const emits = defineEmits(["showPopup"]);
const store = useStore();

const addEngineerData = reactive({
  DisplayName: null,
  Email: null,
});
const displayNameRule = (value) => {
  return validationRegex.englishName.reg.test(value);
};
const emailRule1 = (value) => {
  return validationRegex.email.reg.test(value);
};
const emailRule2 = (value) => {
  const matchedEngineerEmail = shiftMemberListDataVuex.value.filter(
    (item) => item.Email == value
  );
  return matchedEngineerEmail.length ? false : true;
};
const rules = {
  DisplayName: { displayNameRule },
  Email: { emailRule1, emailRule2 },
};
const v$ = useVuelidate(rules, addEngineerData, { $scope: false });

const shiftMemberListDataVuex = computed(() => {
  return store.getters.shiftMemberListData;
});
const handleAddEngineerData = async () => {
  // store.dispatch("handlePageLoadingState", true);
  const sourceData = addEngineerData;
  console.log("handleAddEngineerData - addEngineerData", sourceData);
  const response = await store.dispatch("postCreateEngineer", sourceData);
  console.log("response - postCreateEngineer", response);
  if (response.status == 200) {
    showDialog({
      message: "新增 IR Member 成功",
      confirmButtonText: "返回",
    }).then(() => {
      // on close
      addEngineerData.DisplayName = null;
      addEngineerData.Email = null;
    });
  } else {
    showDialog({
      message: "新增 IR Member 失敗",
      confirmButtonText: "返回",
    }).then(() => {
      // on close
    });
  }
  store.dispatch("getShiftMemberList");
  console.log("response - postCreateEngineer", response);
  emits("showPopup", false);
  // store.dispatch("handlePageLoadingState", false);
};

watch(v$, () => {
  const filterPropertyArray = [];
  if (v$.value.$silentErrors.length) {
    v$.value.$silentErrors.map((obj) => {
      const newObj = { ...obj };
      filterPropertyArray.push(obj.$property);

      return newObj;
    });
  }
  filterPropertyArray.forEach((item, i) => {
    filterPropertyArray[i] = formHelper.whatFieldNameItis(item);
  });
  errorFields.value = Object.values(filterPropertyArray).toString();
  console.log("errorFields", errorFields.value);
});
</script>

<style lang="scss" scoped>
.van-field__error-message {
  display: block;
  // width: 100%;
  margin-left: 140.4px !important;
  line-height: 24px;
}
.fieldRow + .van-field__error-message {
  margin-top: -8px;
}
</style>
