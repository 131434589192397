<template>
  <button
    type="button"
    class="btn btn-success"
    style="padding: 10px 16px 9px 15px; border-radius: 0 6px 6px 0"
    :disabled="v$.invalid"
    @click="popupAddCustomer = true"
  >
    <i class="bi bi-plus-lg"></i>
  </button>

  <van-popup
    v-model:show="popupAddCustomer"
    class="popup popup__right popup__right--medium"
    closeable
    position="right"
  >
    <van-form>
      <div class="popup__header">新增客戶資料</div>
      <div v-if="v$.$silentErrors.length" class="bd-callout bd-callout-warning">
        <strong>填入資料錯誤</strong>
        請修正以下 {{ v$.$silentErrors.length }} 個欄位： {{ errorFields }}
      </div>
      <div class="popup__content">
        <van-field
          v-model="addCustomerData.CustomerEnglishName"
          type="text"
          name="CustomerEnglishName"
          class="fieldRow"
          label="英文名稱"
          label-class="fieldRow__label"
          :border="false"
        />
        <div
          v-if="v$.CustomerEnglishName.cutomerEnglishNameRule1.$invalid"
          class="van-field__error-message"
        >
          {{ validationRegex.englishName.errMessage }}
        </div>
        <!-- <div
          v-if="v$.CustomerEnglishName.cutomerEnglishNameRule2.$invalid"
          class="van-field__error-message"
        >
          此名稱已存在
        </div> -->
        <small class="fieldRow__note">
          為英文全名，可參考維基百科 (Trend Micro Inc)
        </small>

        <van-field
          v-model="addCustomerData.CustomerName"
          type="text"
          name="CustomerName"
          class="fieldRow"
          :class="{
            invalid: v$.CustomerName.cutomerChineseNameRule1.$invalid,
            valid: !v$.CustomerName.cutomerChineseNameRule1.$invalid,
          }"
          label="客戶名稱"
          label-class="fieldRow__label"
          :border="false"
          required
        />
        <div
          v-if="v$.CustomerName.cutomerChineseNameRule1.$invalid"
          class="van-field__error-message"
        >
          {{ validationRegex.chineseName.errMessage }}
        </div>
        <div
          v-if="v$.CustomerName.cutomerChineseNameRule2.$invalid"
          class="van-field__error-message"
        >
          此名稱已存在
        </div>
        <small class="fieldRow__note">為中文全名 (趨勢科技股份有限公司)</small>
        <van-field
          v-model="addCustomerData.CustomerShortName"
          type="text"
          name="CustomerShortName"
          class="fieldRow"
          :class="{
            invalid: v$.CustomerShortName.cutomerShortNameRule1.$invalid,
            valid: !v$.CustomerShortName.cutomerShortNameRule1.$invalid,
          }"
          label="客戶代號"
          label-class="fieldRow__label"
          :border="false"
          required
        />
        <div
          v-if="v$.CustomerShortName.cutomerShortNameRule1.$invalid"
          class="van-field__error-message"
        >
          {{ validationRegex.englishShortName.errMessage }}
        </div>
        <div
          v-if="v$.CustomerShortName.cutomerShortNameRule2.$invalid"
          class="van-field__error-message"
        >
          此代號已存在
        </div>
        <small class="fieldRow__note">
          英文大寫代號，以公司網域為主 (TRENDMICRO)
        </small>
        <div class="fieldRow">
          <label
            for="industryForAdding"
            class="fieldRow__label fieldRow__label--required"
          >
            <!-- 要包一個 tag, 不然畫面上, 字前面會多一個空白 -->
            <span>產業類別</span>
          </label>
          <select
            v-model="addCustomerData.Industry"
            id="industryForAdding"
            class="form-select fieldRow__editField"
            :class="{
              invalid: v$.Industry.industryRule.$invalid,
              valid: !v$.Industry.industryRule.$invalid,
            }"
            :readonly="initConfig.field.industry_picker.readonly"
            :disabled="initConfig.field.industry_picker.disabled"
            @change="handlePickIndustry()"
          >
            <option
              v-for="item in initConfig.field.industry_picker.options"
              :value="item.value"
              :key="item.key"
              :disabled="item.disabled"
            >
              {{ item.key }}
            </option>
            <option
              v-for="item in optionsAllIndustryListVuex"
              :value="item.value"
              :key="item.sid"
              :disabled="item.disabled"
            >
              {{ item.key }}
            </option>
          </select>
          <div
            v-if="v$.Industry.industryRule.$invalid"
            class="van-field__error-message"
          >
            請選取產業類別
          </div>
        </div>
        <div class="fieldRow">
          <label for="hermes_form_industry_id" class="fieldRow__label">
            Ops 產業類別
          </label>
          <select
            v-model="addCustomerData.OpsIndustryId"
            id="hermes_form_industry_id"
            class="form-select fieldRow__editField"
            :disabled="!Object.keys(props.options).length"
          >
            <option
              v-for="item in initConfig.field.ops_industry_picker.options"
              :value="item.value"
              :key="item.key"
              :disabled="item.disabled"
            >
              {{ item.key }}
            </option>
            <template v-if="Object.keys(props.options).length">
              <option
                v-for="item in props.options.COMMON.INDUSTRIES"
                :value="item.value"
                :key="item.key"
              >
                {{ item.key }}
              </option>
            </template>
          </select>
          <van-loading
            v-if="!Object.keys(props.options).length"
            style="position: absolute; left: 57%"
            size="30px"
            vertical
          ></van-loading>
        </div>
        <div class="fieldRow">
          <label for="salesNameForAdding" class="fieldRow__label">業務</label>
          <v-select
            class="vs-datalist form-control"
            :class="{
              invalid: v$.SalesEnglishName.$invalid,
              valid: !v$.SalesEnglishName.$invalid,
            }"
            label="displayValue"
            :options="optionsAllSalesNameListVuex"
            :reduce="(sales) => sales.value"
            v-model="addCustomerData.SalesEnglishName"
            :placeholder="initConfig.placeholder.sales_name_picker.forCaseForm"
            :disabled="initConfig.field.sales_name_picker.readonly"
          />
          <div
            v-if="v$.SalesEnglishName.$invalid"
            class="van-field__error-message"
          >
            請選擇一位業務
          </div>
        </div>
      </div>
      <div class="popup__footer">
        <button
          class="btn btn-primary"
          :disabled="v$.$invalid"
          @click="handleAddCompanyData"
        >
          新增客戶
        </button>
      </div>
    </van-form>
  </van-popup>
</template>

<script setup>
import { ref, reactive, computed, watch, defineEmits, defineProps } from "vue";
import { useStore } from "vuex";
import { showDialog } from "vant";
import formHelper from "../formHelper";
import { form_config } from "../form.config";
import { useVuelidate } from "@vuelidate/core";

import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

const validationRegex = formHelper.validation;
const errorFields = ref(null);
const props = defineProps(["options"]);
// eslint-disable-next-line no-unused-vars
const emits = defineEmits(["showPopup"]);

const store = useStore();
const popupAddCustomer = ref(false);

const initConfig = form_config || [];

const addCustomerData = reactive({
  CustomerEnglishName: null,
  CustomerName: null,
  CustomerShortName: null,
  Industry: null,
  IndustryId: null,
  OpsIndustryId: null,
  SalesId: null,
  SalesEnglishName: null,
});

// Validations
const cutomerEnglishNameRule1 = (value) => {
  if (value === "" || value === null) return true;
  else return validationRegex.englishName.reg.test(value);
};
const cutomerChineseNameRule1 = (value) => {
  return validationRegex.chineseName.reg.test(value);
};
const cutomerChineseNameRule2 = (value) => {
  const matchedCompanyChineseName = allCustomerDataVuex.value.filter(
    (item) => item.CustomerName == value
  );
  return matchedCompanyChineseName.length ? false : true;
};
const cutomerShortNameRule1 = (value) => {
  return validationRegex.englishShortName.reg.test(value);
};
const cutomerShortNameRule2 = (value) => {
  const matchedCompanyShortName = allCustomerDataVuex.value.filter(
    (item) => item.CustomerShortName === value
  );
  return matchedCompanyShortName.length ? false : true;
};
const industryRule = (value) => {
  const matchedIndustry = industryListDataVuex.value.filter(
    (item) => item.DisplayName === value
  );
  return matchedIndustry.length ? true : false;
};
const SalesEnglishNameRule = (value) => {
  const selectedSalesData = salesNameListDataVuex.value.filter(
    (item) => item.DisplayName == value
  );
  if (selectedSalesData.length) {
    addCustomerData.SalesId = selectedSalesData[0].Id;
    return true;
  } else {
    addCustomerData.SalesId = null;
    return false;
  }
};
const rules = {
  CustomerEnglishName: { cutomerEnglishNameRule1 },
  CustomerName: { cutomerChineseNameRule1, cutomerChineseNameRule2 },
  CustomerShortName: { cutomerShortNameRule1, cutomerShortNameRule2 },
  Industry: { industryRule },
  SalesEnglishName: { SalesEnglishNameRule },
};
const v$ = useVuelidate(rules, addCustomerData, { $scope: false });

const allCustomerDataVuex = computed(() => {
  return store.getters.allCustomerData;
});
const optionsAllSalesNameListVuex = computed(() => {
  return store.getters.optionsAllSalesNameList;
});
const optionsAllIndustryListVuex = computed(() => {
  return store.getters.optionsAllIndustryList;
});
const salesNameListDataVuex = computed(() => {
  return store.getters.salesMemberListData;
});
const industryListDataVuex = computed(() => {
  return store.getters.industryListData;
});

const handlePickIndustry = () => {
  const selectedIndustryData = industryListDataVuex.value.filter(
    (item) => item.DisplayName == addCustomerData.Industry
  );
  if (selectedIndustryData.length) {
    console.log("handlePickIndustry", selectedIndustryData[0]);
    addCustomerData.IndustryId = selectedIndustryData[0].Id;
  } else {
    addCustomerData.IndustryId = null;
  }
};
const handleAddCompanyData = async () => {
  // store.dispatch("handlePageLoadingState", true);
  const sourceData = addCustomerData;
  console.log("handleAddCompanyData - addCustomerData", sourceData);
  const response = await store.dispatch("postCreateCompany", sourceData);
  console.log("response - postCreateCompany", response);
  if (response.status == 200) {
    showDialog({
      message: "新增客戶資料成功",
      confirmButtonText: "返回",
    }).then(() => {
      // on close
      addCustomerData.CustomerEnglishName = null;
      addCustomerData.CustomerName = null;
      addCustomerData.CustomerShortName = null;
      addCustomerData.Industry = null;
      addCustomerData.IndustryId = null;
      addCustomerData.OpsIndustryId = null;
      addCustomerData.SalesId = null;
      addCustomerData.SalesEnglishName = null;
    });
  } else {
    showDialog({
      message: "新增客戶資料失敗",
      confirmButtonText: "返回",
    }).then(() => {
      // on close
    });
  }
  store.dispatch("getAllCustomerData");
  console.log("response - postCreateCompany", response);
  popupAddCustomer.value = false;
};

watch(
  allCustomerDataVuex,
  (data) => {
    if (!Object.keys(data).length) {
      console.log("No allCustomerDataVuex data");
      store.dispatch("getAllCustomerData");
    } else {
      console.log("Has allCustomerDataVuex data", data);
    }
  },
  { deep: true, immediate: true }
);

watch(v$, () => {
  const filterPropertyArray = [];
  if (v$.value.$silentErrors.length) {
    v$.value.$silentErrors.map((obj) => {
      const newObj = { ...obj };
      filterPropertyArray.push(obj.$property);

      return newObj;
    });
  }
  filterPropertyArray.forEach((item, i) => {
    filterPropertyArray[i] = formHelper.whatFieldNameItis(item);
  });
  errorFields.value = Object.values(filterPropertyArray).toString();
  console.log("errorFields", errorFields.value);
});

watch(addCustomerData, (data) => {
  if (data) {
    const result = data.CustomerShortName.toUpperCase();
    addCustomerData.CustomerShortName = result;
  }
});
</script>

<style lang="scss" scoped>
.van-field__error-message {
  display: block;
  // width: 100%;
  margin-left: 140.4px !important;
  line-height: 24px;
}
.fieldRow + .van-field__error-message {
  margin-top: -8px;
}
</style>
<style scoped>
/* Error Fields */
.form-select.invalid {
  border-color: var(--bs-form-invalid-border-color);
  padding-right: calc(1.5em + 0.75rem);
  background-color: #ffe3e8;
  background-image: url("data: image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
::v-deep(.invalid .van-field__control) {
  border-color: var(--bs-form-invalid-border-color);
  padding-right: calc(1.5em + 0.75rem);
  background-color: #ffe3e8;
  background-image: url("data: image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

/* v-select component styles */
::v-deep(.vs__dropdown-toggle) {
  padding: 3px 0;
  height: 37px;
  border: 0;
}
::v-deep(.vs__actions) {
  display: none;
}
::v-deep(.vs__search) {
  padding-left: 0;
}
::v-deep(.vs__selected) {
  margin-top: 0;
  margin-left: 0;
  padding-left: 0;
  width: 350px;
  line-height: 32px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
::v-deep(.invalid.v-select) {
  padding-top: 3px;
  padding-bottom: 3px;
  border-color: var(--bs-form-invalid-border-color);
  padding-right: calc(1.5em + 0.75rem);
  background-color: #ffe3e8;
  background-image: url("data: image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
</style>
