<template>
  <div v-if="props.assetsData.length" class="searchInput mb-3">
    <input
      type="text"
      v-model="searchAssetsTerm"
      id="filterAssetsList"
      class="filter__input form-control"
      placeholder="輸入關鍵字..."
    />
  </div>
  <div v-if="tableAssets.rows.length" class="tableWrapper">
    <table-lite
      class="tableAssets"
      :is-static-mode="true"
      :is-fixed-first-row="true"
      :max-height="650"
      :is-slot-mode="true"
      :columns="tableAssets.columns"
      :rows="tableAssets.rows"
      :total="tableAssets.totalRecordCount"
      :sortable="tableAssets.sortable"
      :page-options="tableAssets.pageOptions"
      :page-size="50"
      :messages="tableAssets.messages"
      @row-clicked="tableAssetsRowClicked"
      @is-finished="tableLoadingFinish"
    >
      <template v-slot:Description="data">
        <template v-if="data.value.Description">
          <van-text-ellipsis
            rows="1"
            :content="data.value.Description"
            expand-text="more"
            collapse-text="...close"
          />
        </template>
        <template v-else> - </template>
      </template>
      <template v-slot:Note="data">
        <template v-if="data.value.Note">
          <van-text-ellipsis
            rows="1"
            :content="data.value.Note"
            expand-text="more"
            collapse-text="...close"
          />
        </template>
        <template v-else> - </template>
      </template>
      <template v-slot:UploadStatus="data">
        <i
          v-if="data.value.UploadedZipFiles.length"
          :class="data.value.assetUploadStatusClassName"
        ></i>
        {{ data.value.assetUploadStatusDisplay }}
      </template>
      <template v-slot:UploadProgress="data">
        <ul class="uploadStatusProress">
          <li
            class="uploadStatusProress__section"
            :class="{
              'bg-success':
                data.value.assetUploadStatus == 1 ||
                data.value.assetUploadStatus == 2 ||
                data.value.assetUploadStatus == -2 ||
                data.value.assetUploadStatus == 3 ||
                data.value.assetUploadStatus == -3 ||
                data.value.assetUploadStatus == 33 ||
                data.value.assetUploadStatus == 22,
              'bg-danger': data.value.assetUploadStatus == -1,
              'bg-warning': data.value.assetUploadStatus == 0,
            }"
          >
            toCloud
            <template
              v-if="
                data.value.assetUploadStatus == 1 ||
                data.value.assetUploadStatus == 2 ||
                data.value.assetUploadStatus == -2 ||
                data.value.assetUploadStatus == 3 ||
                data.value.assetUploadStatus == -3 ||
                data.value.assetUploadStatus == 33 ||
                data.value.assetUploadStatus == 22
              "
            >
              <i class="bi bi-check"></i>
            </template>
            <template v-else-if="data.value.assetUploadStatus == -1">
              <i class="bi bi-exclamation-triangle-fill"></i>
            </template>
            <template v-else-if="data.value.assetUploadStatus == 0">
              <i class="fa-solid fa-cog fa-spin"></i>
            </template>
          </li>
          <li
            class="uploadStatusProress__section"
            :class="{
              'bg-success':
                data.value.assetUploadStatus == 2 ||
                data.value.assetUploadStatus == 3 ||
                data.value.assetUploadStatus == -3 ||
                data.value.assetUploadStatus == 22 ||
                data.value.assetUploadStatus == 33,
              'bg-danger': data.value.assetUploadStatus == -2,
              'bg-warning': data.value.assetUploadStatus == 1,
            }"
          >
            Unzip
            <template
              v-if="
                data.value.assetUploadStatus == 2 ||
                data.value.assetUploadStatus == 3 ||
                data.value.assetUploadStatus == -3 ||
                data.value.assetUploadStatus == 22 ||
                data.value.assetUploadStatus == 33
              "
            >
              <i class="bi bi-check"></i>
            </template>
            <template v-else-if="data.value.assetUploadStatus == -2">
              <i class="bi bi-exclamation-triangle-fill"></i>
            </template>
            <template v-else-if="data.value.assetUploadStatus == 1">
              <i class="fa-solid fa-cog fa-spin"></i>
            </template>
          </li>
          <li
            class="uploadStatusProress__section"
            :class="{
              'bg-success': data.value.assetUploadStatus == 3,
              'bg-danger': data.value.assetUploadStatus == -3,
              'bg-warning':
                data.value.assetUploadStatus == 2 ||
                data.value.assetUploadStatus == 33,
            }"
          >
            Log分析
            <template v-if="data.value.assetUploadStatus == 3">
              <i class="bi bi-check"></i>
            </template>
            <template v-else-if="data.value.assetUploadStatus == -3">
              <i class="bi bi-exclamation-triangle-fill"></i>
            </template>
            <template
              v-else-if="
                data.value.assetUploadStatus == 2 ||
                data.value.assetUploadStatus == 33
              "
            >
              <i class="fa-solid fa-cog fa-spin"></i>
            </template>
          </li>
        </ul>
      </template>
      <template v-slot:function="data">
        <div class="columnFunction">
          <template v-if="data.value.UploadedZipFiles.length">
            <a
              class="columnFunction__button"
              v-if="
                data.value.assetUploadStatus == 2 ||
                data.value.assetUploadStatus == 3 ||
                data.value.assetUploadStatus == 22 ||
                data.value.assetUploadStatus == 33 ||
                data.value.assetUploadStatus == -3
              "
              :href="data.value.DownloadURL"
            >
              <i class="bi bi-cloud-arrow-down-fill"></i>
            </a>
          </template>
          <template v-if="data.value.UploadedZipFiles.length">
            <i
              class="bi bi-pencil-fill columnFunction__button"
              @click="handleEditTableCell(data.value.Id)"
            ></i>
          </template>
          <template v-else>
            <i
              class="bi bi-pencil-fill columnFunction__button"
              @click="handleEditTableCell(data.value.Id)"
            ></i>
          </template>
          <i
            v-if="ENABLE_FUNCTION_ASSETS_DELETE"
            class="bi bi-trash3-fill columnFunction__button"
            @click="handleShowDeleteTableAssetsConfirm(data.value.Id)"
          ></i>
        </div>
      </template>
    </table-lite>
  </div>

  <div v-if="!tableAssets.rows.length" class="noDataDisplay">
    <i class="bi bi-file-earmark noDataDisplay__icon"></i>
    <strong class="noDataDisplay__text">無資料</strong>
  </div>

  <van-popup
    v-model:show="popupEditAsset"
    class="popup popup__right popup__right--medium"
    closeable
    position="right"
  >
    <div v-if="isProgress.popupLoading" class="position--center">
      <van-loading class="text-center" style="margin-top: 20%" size="60" />
    </div>
    <div :class="{ 'opacity-75': isProgress.popupLoading }">
      <div class="popup__header">編輯主機資訊</div>
      <div v-if="v$.$silentErrors.length" class="bd-callout bd-callout-warning">
        <strong>填入資料錯誤</strong>
        請修正以下 {{ v$.$silentErrors.length }} 個欄位： {{ errorFields }}
      </div>
      <div class="popup__content">
        <van-field
          v-model="editAssetData.Role"
          type="text"
          name="Role"
          class="fieldRow"
          label="Server/User"
          label-class="fieldRow__label"
          :border="false"
        />
        <van-field
          v-model="editAssetData.ServerIp"
          type="text"
          name="ip"
          class="fieldRow"
          label="IP"
          label-class="fieldRow__label"
          :border="false"
        />
        <van-field
          v-model="editAssetData.DomainName"
          type="text"
          name="domain_name"
          class="fieldRow"
          label="Domain Name"
          label-class="fieldRow__label"
          :border="false"
        />
        <van-field
          v-model="editAssetData.HostName"
          type="text"
          name="domain_name"
          class="fieldRow"
          label="Host Name"
          label-class="fieldRow__label"
          :border="false"
        />
        <!-- <van-field
          v-model="editAssetData.AccountName"
          type="text"
          name="name"
          class="fieldRow"
          label="Account Name"
          label-class="fieldRow__label"
          :border="false"
        /> -->
        <div class="fieldRow">
          <label for="type_display" class="fieldRow__label"> 是否受駭 </label>
          <select
            v-model="editAssetData.IsHacked"
            id="is_hacked"
            name="is_hacked"
            class="form-select fieldRow__editField"
          >
            <option
              v-for="item in initConfig.field.asset_is_hacked.options"
              :value="item.value"
              :key="item.key"
              :disabled="item.disabled"
            >
              {{ item.key }}
            </option>
          </select>
        </div>

        <div class="fieldRow">
          <label for="first_hack_time" class="fieldRow__label">
            檢查日期
          </label>
          <VueDatePicker
            v-model="editAssetData.SamplingDateValue"
            class="form-control"
            placeholder="選擇日期"
            model-type="yyyy-MM-dd"
            format="yyyy / MM / dd"
            month-name-format="long"
            week-start="0"
            locale="zh-TW"
            year-first
            auto-apply
            :enable-time-picker="false"
            :max-date="new Date()"
            :partial-range="false"
            :action-row="{ showPreview: false }"
            :day-names="['日', '一', '二', '三', '四', '五', '六']"
            :space-confirm="false"
            :offset="0"
            @update:model-value="onSamplingDatepickerConfirm"
          />
        </div>

        <div class="fieldRow">
          <label for="first_hack_time" class="fieldRow__label">
            最早入侵時間
          </label>
          <VueDatePicker
            v-model="editAssetData.first_hack_time_value"
            class="form-control"
            placeholder="選擇日期"
            model-type="yyyy-MM-dd"
            format="yyyy / MM / dd"
            month-name-format="long"
            week-start="0"
            locale="zh-TW"
            year-first
            auto-apply
            :enable-time-picker="false"
            :max-date="new Date()"
            :partial-range="false"
            :action-row="{ showPreview: false }"
            :day-names="['日', '一', '二', '三', '四', '五', '六']"
            :space-confirm="false"
            :offset="0"
            @update:model-value="onDatepickerConfirm"
          />
        </div>

        <van-field
          v-model="editAssetData.InfectedSourceIP"
          type="text"
          name="infect_source_ip"
          class="fieldRow"
          :class="{
            invalid: v$.InfectedSourceIP.$invalid,
            valid: !v$.InfectedSourceIP.$invalid,
          }"
          label="來源 IP"
          label-class="fieldRow__label"
          :border="false"
        />
        <div
          v-if="v$.InfectedSourceIP.maxLengthValue.$invalid"
          class="van-field__error-message"
        >
          {{ v$.InfectedSourceIP.maxLengthValue.$message }}
        </div>

        <van-field
          v-model="editAssetData.AccountName"
          type="text"
          name="account_name"
          class="fieldRow"
          :class="{
            invalid: v$.AccountName.$invalid,
            valid: !v$.AccountName.$invalid,
          }"
          label="帳號"
          label-class="fieldRow__label"
          :border="false"
        />
        <div
          v-if="v$.AccountName.maxLengthValue.$invalid"
          class="van-field__error-message"
        >
          {{ v$.AccountName.maxLengthValue.$message }}
        </div>

        <van-field
          v-model="editAssetData.Description"
          type="textarea"
          name="description"
          class="fieldRow fieldRow--baseline"
          :class="{
            invalid: v$.Description.$invalid,
            valid: !v$.Description.$invalid,
          }"
          label="入侵概況"
          label-class="fieldRow__label"
          rows="5"
          :border="false"
        />
        <div
          v-if="v$.Description.maxLengthValue.$invalid"
          class="van-field__error-message"
        >
          {{ v$.Description.maxLengthValue.$message }}
        </div>

        <van-field
          v-model="editAssetData.Note"
          type="textarea"
          name="note"
          class="fieldRow fieldRow--baseline"
          :class="{
            invalid: v$.Note.$invalid,
            valid: !v$.Note.$invalid,
          }"
          label="備註"
          label-class="fieldRow__label"
          rows="5"
          :border="false"
        />
        <div
          v-if="v$.Note.maxLengthValue.$invalid"
          class="van-field__error-message"
        >
          {{ v$.Note.maxLengthValue.$message }}
        </div>
      </div>
      <div class="popup__footer">
        <button
          type="button"
          class="btn btn-primary"
          :disabled="v$.$invalid"
          @click="handleSaveAssetData(editAssetData.Id)"
        >
          儲存
        </button>
      </div>
    </div>
  </van-popup>

  <van-popup
    v-model:show="popupDeleteAssetConfirm"
    class="popup"
    :close-on-click-overlay="false"
    round
    style="width: 350px"
  >
    <div v-if="isProgress.popupLoading" class="position--center">
      <van-loading class="text-center" style="margin-top: 20%" size="60" />
    </div>
    <div :class="{ 'opacity-75': isProgress.popupLoading }">
      <div class="popup__header">刪除主機資訊？</div>
      <div class="popup__content">
        刪除後，該主機資料及所上傳檔案將全數一併刪除，請確認是否要刪除此主機資訊。
      </div>
      <div class="popup__footer">
        <button
          type="button"
          class="btn btn-danger"
          @click="popupDeleteAssetConfirm = false"
        >
          取消刪除
        </button>
        <button
          type="button"
          class="btn btn-primary"
          @click.stop="handleDeleteAssetData()"
        >
          刪除主機
        </button>
      </div>
    </div>
  </van-popup>
</template>

<script setup>
import { ref, reactive, computed, watch, defineEmits, defineProps } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import TableLite from "vue3-table-lite";
import { showDialog } from "vant";
import moment from "moment";
import formHelper from "../formHelper";
import { useVuelidate } from "@vuelidate/core";
import { maxLength } from "@vuelidate/validators";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

import { special } from "../special";
import { form_config } from "../form.config";

const props = defineProps(["assetsData"]);
// eslint-disable-next-line no-unused-vars
const emits = defineEmits(["reloadData"]);
const ENABLE_FUNCTION_ASSETS_DELETE =
  special.ENABLE_FUNCTION_ASSETS_DELETE || false;
const isProgress = computed(() => {
  return store.getters.isProgress;
});
let parmsId = ref(null);
const route = useRoute();
const store = useStore();

// eslint-disable-next-line no-undef
const initConfig = form_config || [];
const deleteAssetTargetId = ref(null);
// const editAssetTargetId = ref(null);
const popupEditAsset = ref(false);
const popupDeleteAssetConfirm = ref(false);
// const showCalendar = ref(false);

// Init table settings
const searchAssetsTerm = ref(""); // Search text
const tableAssets = reactive({
  isLoading: false,
  columns: [
    {
      label: "#",
      field: "index",
      width: "",
      sortable: true,
      isKey: true,
    },
    {
      label: "功能",
      field: "function",
      width: "",
      sortable: true,
    },
    {
      label: "Upload 狀態",
      field: "UploadStatus",
      headerClasses: ["hide"],
      columnClasses: ["hide"],
      width: "",
      sortable: true,
    },
    {
      label: "Upload 狀態",
      field: "UploadProgress",
      width: "215px",
      sortable: true,
    },
    {
      label: "上傳時間",
      field: "creation_time_display",
      sortable: true,
    },
    {
      label: "檢查日期",
      field: "SamplingDateDisplay",
      width: "",
      sortable: true,
      display: function (row) {
        if (row.SamplingDateDisplay) {
          return `${row.SamplingDateDisplay}`;
        } else {
          return "-";
        }
      },
    },
    {
      label: "檢查人員",
      field: "UploadEngineerEnglishName",
      width: "",
      sortable: true,
      display: function (row) {
        if (row.UploadEngineerEnglishName) {
          return `${row.UploadEngineerEnglishName}`;
        } else {
          return "-";
        }
      },
    },
    {
      label: "Role",
      field: "Role",
      width: "6%",
      sortable: true,
      display: function (row) {
        if (row.Role) {
          return `${row.Role}`;
        } else {
          return "-";
        }
      },
    },
    {
      label: "IP",
      field: "ServerIp",
      width: "",
      sortable: true,
      display: function (row) {
        if (row.ServerIp) {
          return `${row.ServerIp}`;
        } else {
          return "-";
        }
      },
    },
    {
      label: "Domain",
      field: "DomainName",
      width: "",
      sortable: true,
      display: function (row) {
        if (row.DomainName) {
          return `${row.DomainName}`;
        } else {
          return "-";
        }
      },
    },
    {
      label: "主機",
      field: "HostName",
      width: "",
      sortable: true,
      display: function (row) {
        if (row.HostName) {
          return `${row.HostName}`;
        } else {
          return "-";
        }
      },
    },
    {
      label: "受駭",
      field: "is_hacked_display",
      width: "",
      sortable: true,
    },
    {
      label: "最早入侵時間",
      field: "first_hack_time_display",
      width: "",
      sortable: true,
      display: function (row) {
        if (row.first_hack_time_display) {
          return `${row.first_hack_time_display}`;
        } else {
          return "-";
        }
      },
    },
    {
      label: "帳號",
      field: "AccountName",
      width: "",
      sortable: true,
      display: function (row) {
        if (row.AccountName) {
          return `${row.AccountName}`;
        } else {
          return "-";
        }
      },
    },
    {
      label: "入侵概況",
      field: "Description",
      width: "", //15%
      sortable: false,
    },
    {
      label: "備註",
      field: "Note",
      width: "10%",
      sortable: false,
    },
  ],
  rows: computed(() => {
    console.log("props.assetsData", props.assetsData);
    return props.assetsData.filter(
      (x) =>
        x.Role.toLowerCase().includes(searchAssetsTerm.value.toLowerCase()) ||
        x.assetUploadStatusDisplay
          .toLowerCase()
          .includes(searchAssetsTerm.value.toLowerCase()) ||
        x.SamplingDateDisplay.toLowerCase().includes(
          searchAssetsTerm.value.toLowerCase()
        ) ||
        x.UploadEngineerEnglishName.toLowerCase().includes(
          searchAssetsTerm.value.toLowerCase()
        ) ||
        x.ServerIp.includes(searchAssetsTerm.value.toLowerCase()) ||
        x.DomainName.toLowerCase().includes(
          searchAssetsTerm.value.toLowerCase()
        ) ||
        x.HostName.toLowerCase().includes(
          searchAssetsTerm.value.toLowerCase()
        ) ||
        x.SamplingDateDisplay.toLowerCase().includes(
          searchAssetsTerm.value.toLowerCase()
        ) ||
        x.is_hacked_display
          .toLowerCase()
          .includes(searchAssetsTerm.value.toLowerCase()) ||
        x.first_hack_time_display
          .toLowerCase()
          .includes(searchAssetsTerm.value.toLowerCase()) ||
        x.InfectedSourceIP.toLowerCase().includes(
          searchAssetsTerm.value.toLowerCase()
        ) ||
        x.AccountName.toLowerCase().includes(
          searchAssetsTerm.value.toLowerCase()
        ) ||
        x.Description.toLowerCase().includes(
          searchAssetsTerm.value.toLowerCase()
        )
    );
  }),
  // rowClasses: function (row) {
  //   return row.className4CardBg;
  // },
  totalRecordCount: computed(() => {
    return tableAssets.rows.length;
  }),
  sortable: {
    order: "index",
    sort: "asc",
  },
  messages: {
    pagingInfo: "現在顯示 {0} 到 {1}筆 共{2}筆",
    pageSizeChangeLabel: "每頁筆數:",
    gotoPageLabel: "現在頁數:",
    noDataAvailable: "無資料",
  },
  pageOptions: [
    {
      value: 20,
      text: 20,
    },
    {
      value: 50,
      text: 50,
    },
    {
      value: 100,
      text: 100,
    },
    {
      value: 500,
      text: 500,
    },
    {
      value: 10000,
      text: "All",
    },
  ],
});
const editAssetData = ref(null);
const assetForm = reactive({
  Id: null,
  Role: null,
  ServerIp: null,
  DomainName: null,
  IsHacked: null,
  FirstHackTime: null,
  SamplingDate: null,
  InfectedSourceIP: null,
  AccountName: null,
  Description: null,
  HostName: null,
  Note: null,
});
const tableLoadingFinish = () => {
  console.log("tableLoadingFinish");
};
const tableAssetsRowClicked = (row) => {
  console.log("rowClicked - row", row);
};
const showEditAssetPopup = (id) => {
  console.log("showEditAssetPopup - id:", id);
  popupEditAsset.value = true;
};

// Vulidate start
const errorFields = ref(null);
const rules = {
  InfectedSourceIP: { maxLengthValue: maxLength(200) },
  AccountName: { maxLengthValue: maxLength(200) },
  Description: { maxLengthValue: maxLength(4000) },
  Note: { maxLengthValue: maxLength(4000) },
};
const v$ = useVuelidate(rules, editAssetData, { $scope: false });
// Vulidate end

const onDatepickerConfirm = (value) => {
  console.log("onDatepickerConfirm", value);
  // showCalendar.value = false;
  editAssetData.value.first_hack_time_display =
    moment(value).format("YYYY/MM/DD");
  // editAssetData.value.first_hack_time_value =
  //   moment(value).format("YYYY-MM-DD");
};
const onSamplingDatepickerConfirm = (value) => {
  console.log("onSamplingDatepickerConfirm", value);
  editAssetData.value.SamplingDateDisplay = moment(value).format("YYYY/MM/DD");
};
// const handleDownloadZipFile = (assetId) => {
//   console.log("handleDownloadZipFile", assetId);
// };
const handleEditTableCell = (assetId) => {
  console.log("handleEditTableCell - assetId", assetId);
  const editTargetAssetData = props.assetsData.filter(
    (asset) => asset.Id === assetId
  );
  editAssetData.value = editTargetAssetData[0];
  console.log("editAssetData", editAssetData.value);
  showEditAssetPopup(assetId);
};
const handleShowDeleteTableAssetsConfirm = (assetId) => {
  console.log("handleShowDeleteTableAssetsConfirm - assetId", assetId);
  deleteAssetTargetId.value = assetId;
  popupDeleteAssetConfirm.value = true;
};
// const handleSendAssetComment = (assetId) => {
//   console.log("handleSendAssetComment - assetId", assetId);
// };
const handleSaveAssetData = async (assetId) => {
  store.dispatch("handlePopupLoadingState", true);
  console.log("handleSaveAssetData - Save asset id:", assetId);
  console.log(
    "handleSaveAssetData - editAssetData.value:",
    editAssetData.value
  );
  assetForm.Id = assetId;
  assetForm.Role = editAssetData.value.Role;
  assetForm.ServerIp = editAssetData.value.ServerIp;
  assetForm.DomainName = editAssetData.value.DomainName;
  assetForm.HostName = editAssetData.value.HostName;
  assetForm.IsHacked = editAssetData.value.IsHacked;

  // 若日期為 null 則不送出該 key. Due to backend login
  if (editAssetData.value.first_hack_time_value) {
    console.log(
      "editAssetData.value.first_hack_time_value",
      editAssetData.value.first_hack_time_value
    );
    assetForm.FirstHackTime = editAssetData.value.first_hack_time_value
      ? editAssetData.value.first_hack_time_value
      : null;
  } else {
    delete assetForm.FirstHackTime;
  }
  if (editAssetData.value.SamplingDateValue) {
    assetForm.SamplingDate = editAssetData.value.SamplingDateValue
      ? editAssetData.value.SamplingDateValue
      : null;
  } else {
    delete assetForm.SamplingDate;
  }
  assetForm.InfectedSourceIP = editAssetData.value.InfectedSourceIP;
  assetForm.AccountName = editAssetData.value.AccountName;
  assetForm.Description = editAssetData.value.Description;
  assetForm.Note = editAssetData.value.Note;
  console.log("assetForm", assetForm);
  const sourceData = {
    assetId: assetForm.Id,
    data: assetForm,
  };
  console.log("sourceData", sourceData);
  try {
    const response = await store.dispatch("putUpdateAssetData", sourceData);

    if (response.status !== 200) {
      store.dispatch("handlePopupLoadingState", false);
      showDialog({
        message: "Update asset data FAIL.",
        confirmButtonText: "返回",
      }).then(() => {
        // on close
        emits("reloadData", parmsId);
        store.dispatch("handlePopupLoadingState", false);
      });
    } else {
      setTimeout(() => {
        popupEditAsset.value = false;
        showDialog({
          message: "主機資料更新成功！",
          confirmButtonText: "返回",
        }).then(() => {
          // on close
          emits("reloadData", parmsId);
          store.dispatch("handlePopupLoadingState", false);
        });
      }, 2000);
    }
  } catch (err) {
    console.log("Update asset data FAIL.", err);
  }
};

const handleDeleteAssetData = async () => {
  store.dispatch("handlePopupLoadingState", true);
  const sourceData = {
    assetId: deleteAssetTargetId.value,
  };
  console.log("sourceData - handleDeleteAssetData", sourceData);
  try {
    const response = await store.dispatch("deleteAssetData", sourceData);

    if (response.status !== 200) {
      showDialog({
        message: "Delete asset data FAIL.",
        confirmButtonText: "返回",
      }).then(() => {
        // on close
        store.dispatch("handlePopupLoadingState", false);
      });
    } else {
      setTimeout(() => {
        store.dispatch("handlePopupLoadingState", false);
        popupDeleteAssetConfirm.value = false;
        emits("reloadData", parmsId);
        console.log("Delete asset data SUCCESS.");
      }, 2000);
    }
  } catch (error) {
    console.log("Delete asset data FAIL.", error);
  }
};

watch(
  route,
  () => {
    parmsId.value = route.params.caseId;
  },
  { deep: true, immediate: true }
);

watch(v$, () => {
  const filterPropertyArray = [];
  if (v$.value.$silentErrors.length) {
    v$.value.$silentErrors.map((obj) => {
      const newObj = { ...obj };
      filterPropertyArray.push(obj.$property);

      return newObj;
    });
  }
  filterPropertyArray.forEach((item, i) => {
    filterPropertyArray[i] = formHelper.whatFieldNameItis(item);
  });
  errorFields.value = Object.values(filterPropertyArray).toString();
  console.log("errorFields", errorFields.value);
});
</script>

<style lang="scss" scoped>
.dp__theme_light {
  --dp-background-color: none;
  --dp-border-color-hover: none;
  --dp-border-color: none;
  --dp-input-padding: 0px 30px 0px 15px;
}
.componentCalendar {
  position: absolute;
  right: 0;
  width: 80%;
  height: 380px;
  background-color: #fff;
  z-index: 9;
  box-shadow: 0 0.5rem 1rem rgba(var(--bs-inverse-rgb), 0.075);
}
.van-field__error-message {
  display: block;
  // width: 100%;
  margin-left: 140.4px !important;
  line-height: 24px;
}
.fieldRow + .van-field__error-message {
  margin-top: -8px;
}
.uploadStatusProress {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  font-size: 0.65rem;
  color: #aeaeae;
  background-color: #dddada;
  border-radius: 15px;
  overflow: hidden;
  // border: 1px solid #bbb;

  &__section {
    width: 33.333%;
    padding: 4px;
    text-align: center;
    border-left: 1px dashed #bababa;
    &:first-child {
      border-left-width: 0;
    }
  }
  &__section.bg-success {
    color: #fff;
  }
  &__section.bg-danger {
    color: #fff;
  }
  &__section.bg-warning {
    color: #fff;
  }
}
</style>

<style scoped>
:deep(.vtl-row) {
  overflow-y: auto;
}
/* Datepicker */
::v-deep(.dp__input_icons) {
  padding-left: 0;
}
/* Error Fields */
::v-deep(.invalid .van-field__control) {
  border-color: var(--bs-form-invalid-border-color);
  padding-right: calc(1.5em + 0.75rem);
  background-color: #ffe3e8;
  background-image: url("data: image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
</style>
